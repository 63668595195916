<template>
  <vx-card>
    <div>
      <div class="vx-col mb-6 w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Warehouse</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedWarehouse"
              :options="optionWarehouse"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableWarehouse"
              @select="handleBTNWarehouse()"
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Valid From</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="dateFrom"
                type="date"
                :min="today"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Valid To</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="dateTo"
                type="date"
                :min="dateFrom"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
      </div>
      <vs-table
        stripe
        border
        search
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @change-page="handleChangePage"
        @search="handleSearch"
        @sort="handleSort"
      >
        <template slot="header">
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown> -->
        </template>
        <template slot="thead">
          <vs-th sort-key="number">Vehicle Number</vs-th>
          <vs-th sort-key="zone_name">Zone Operation</vs-th>
          <vs-th sort-key="name">Driver Name</vs-th>
          <vs-th sort-key="name">Availability</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].number">
              {{ data[indextr].number }}
            </vs-td>
            <vs-td :data="data[indextr].zone_name">
              {{ data[indextr].zone_name }}
            </vs-td>
            <vs-td>
              <div class="vx-col sm:w-4/5 w-full">
                <multiselect
                  class="selectExample"
                  v-model="table.data[indextr].driver_id"
                  :options="optionalDriver"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="100"
                  :limit="3"
                  placeholder="Type to search"
                  :searchable="true"
                  :custom-label="customLabelDriver"
                  @search-change="handleSearchDriver"
                />
              </div>
            </vs-td>
            <vs-td>
              <div class="center con-switch">
                <vs-switch v-model="table.data[indextr].availability">
                </vs-switch>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
      <div class="vx-row flex justify-between mt-5 m-10">
        <div>
          <vs-button class="mb-2" v-on:click="SubmitForm()">Generate</vs-button>
        </div>
        <div>
          <vs-button class="mb-2" v-on:click="handleClose()">Close</vs-button>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  components: {},

  mounted() {
    this.getWarehouse();
  },

  data() {
    return {
      table: this.tableDefaultState(),
      today: moment().format("YYYY-MM-DD"),
      dateFrom: moment().format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
      warehouseID: this.$userLogin.warehouse_id,
      optionWarehouse: [],
      selectedWarehouse: {},
      optionalDriver: [],
      selectedDriver: {},
      select2: [{ value: false }],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    changeAvailability(index) {
      this.table.data[index].availability =
        !this.table.data[index].availability;
      console.log(this.table.data[index]);
    },

    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      //internal search without calling api
      this.table.data = this.table.data.filter((item) => {
        return item.number.toLowerCase().includes(searching.toLowerCase());
      });
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getData();
    },
    getData(params) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/driver-availability/vehicle-by-warehouse", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            warehouse_id: this.warehouseID ? this.warehouseID : null,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            // this.table.data = [];
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            // this.table.data = resp.data.records;
            for (let index = 0; index < resp.data.records.length; index++) {
              var tempData = {};
              tempData.availability = true;
              tempData.id = resp.data.records[index].id;
              tempData.number = resp.data.records[index].number;
              tempData.zone_name = resp.data.records[index].zone_name;
              tempData.driver_id = null;
              tempData.indextr = index;
              this.table.data.push(tempData);
            }

            console.log("weee", this.table.data);

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    handleBTNWarehouse() {
      console.log(">>>>qqq", this.selectedWarehouse.id);
      this.table.data = [];

      let params = {
        ...this.table,
      };

      this.warehouseID = this.selectedWarehouse.id;

      this.getData(params);
      this.getDriver();
    },

    SubmitForm() {
      console.log(">>>>>ini submit", this.table.data);
      //check if driver have 2 or more schedule vehicle in same time then set error
      let driverCountMap = {};
      let driverError = false;
      let driverErrorList = [];
      let driverErrorListName = [];

      // Count the schedules for each driver
      this.table.data.forEach((schedule, index) => {
        if (schedule.driver_id != null) {
          let driver = schedule.driver_id.name;
          let driverName = schedule.driver_id.name;
          console.log(">>>>>ini driver", driver, driverName);

          // Increment the count of schedules for the current driver
          driverCountMap[driver] = (driverCountMap[driver] || 0) + 1;

          // If the count exceeds 1, mark an error and add the driver to the error lists
          if (driverCountMap[driver] > 1) {
            //break the loop
            driverError = true;
            driverErrorList.push(driver);
            driverErrorListName.push(driverName);
          }
        }
      });

      if (driverErrorList.length > 1) {
        driverErrorListName = driverErrorListName.slice(0, 2);
        driverErrorListName.push("...");
      } else {
        driverErrorListName = driverErrorListName.join(", ");
      }

      // // If there is an error, notify the user and return
      if (driverError) {
        this.$vs.notify({
          title: "Error",
          text: `Driver ${driverErrorListName} have 2 or more schedules in the same time`,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      let submitItem = [];

      for (let index = 0; index < this.table.data.length; index++) {
        let dataSubmit = {};
        dataSubmit.vehicle_id = this.table.data[index].id;
        dataSubmit.is_availibility = this.table.data[index].availability;
        dataSubmit.driver_id = this.table.data[index].driver_id
          ? this.table.data[index].driver_id.id
          : null;
        dataSubmit.vehicle_name = this.table.data[index].number;
        dataSubmit.zone_name = this.table.data[index].zone_name;

        submitItem.push(dataSubmit);
      }

      console.log(">>>>>ini submit", submitItem);

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to Process this data?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .post("/api/wms/v1/driver-availability/generate", {
              vehicle_availability: submitItem,
              warehouse_id: this.selectedWarehouse.id,
              date_from: this.dateFrom,
              date_to: this.dateTo,
            })
            .then((resp) => {
              console.log(resp);
              if (resp.code == 200) {
                this.$vs.notify({
                  title: "Success",
                  text: "Reservation has been added",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
                this.handleClose();
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: resp.message,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              }

              this.$vs.loading.close();
            });
        },
      });
    },

    getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouses", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
            by_personal: 1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
              if (this.optionWarehouse.length > 0) {
                this.selectedWarehouse = this.optionWarehouse[0];
              } else {
                this.optionWarehouse = [];
                this.selectedWarehouse = {};
              }
            } else {
              this.optionWarehouse = [];
              this.selectedWarehouse = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDriver() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/drivers", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            warehouse_id: this.warehouseID
              ? this.warehouseID
              : this.selectedWarehouse.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalDriver = resp.data.records;
              if (this.optionalDriver.length > 0) {
                this.selectedDriver = this.optionalDriver[0];
              } else {
                this.optionalDriver = [];
                this.selectedDriver = {};
              }
            } else {
              this.optionalDriver = [];
              this.selectedDriver = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleSearchDriver(query) {
      if (query != "") {
        this.table.search = query;

        this.getDriver();
      }
    },

    customLableWarehouse({ code, name }) {
      return `${code} ${name}`;
    },
    customLabelDriver({ name }) {
      return `${name}`;
    },
    handleClose() {
      // back to list
      this.$vs.loading();
      this.$router.push({ name: "vehicle-availability" });
      this.$vs.loading.close();
    },
  },
  watch: {
    detail() {
      this.getData();
      this.getTerritory();
    },
    //valid to must be same or greater than valid from
    dateFrom: function (val) {
      if (this.dateTo < val) {
        this.dateTo = val;
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>

<style>
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
